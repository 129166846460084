<!--
 * @Author: wxb
 * @Date: 2021-06-21 15:13:29
 * @LastEditTime: 2021-11-03 09:49:08
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Organization\compareOrg.vue
-->
<template>
  <div class="containner">
    <div class="compare-container"
         :style="{paddingTop:onfixed?'195px':'0px'}">
      <div class="compare-header"
           :class="{'compare-header-fixed':onfixed}"
           :style="{left:fixedleft}">
        <div class="compare-header-tools">
          <el-checkbox v-model="hideSome">显示不同项</el-checkbox>
          <el-checkbox v-model="showSome">显示相同项</el-checkbox>
          <el-checkbox v-model="hightSome">高亮显示不同项</el-checkbox>
        </div>
        <div class="compare-header-item"
             v-for="(item,index) in companyList"
             :key="index">
          <div class="delete-item"
               @click="delCompareItem(index)">
            <i class="el-icon-close"></i>
          </div>
          <div class="change-item el-icon-sort"
               @click="changePosition(index)"></div>
          <div class="goodsinfo">
            <div class="product-title"
                 @click="handleLinkToDetail(index)">
              {{item.mingCh}}
            </div>
            <div class="product-tools">
              <button class="collection"
                      type="button"
                      @click="changeCompany(index)"><span v-if="item.mingCh==='机构名称'">添加机构</span><span v-else>换机构</span></button>
            </div>
          </div>
        </div>
        <div class="compare-header-add">
          <!-- <button v-if="companyList.length<6"
                    @click="addCompareItem">添加机构</button> -->
          <!-- <button v-show="elements['company_compare:btn_add']"
                  @click="addCompareItem">添加机构</button> -->
        </div>
      </div>
      <div class="compare-content">
        <div class="compare-item"
             ref="baseInfo">
          <div class="compare-item-title"
               v-show="compareList4BaseInfo.length>0"
               :style="{width:compareItemTItleWidth+'px'}">基本信息</div>
          <div class="compare-item-content">
            <div class="box-content"
                 v-for="(item,index) in compareList4BaseInfo"
                 :key="index"
                 v-show="hideSome ? (hideSome ? item.different : true) : (showSome ? item.isSame : true)"
                 :class="{high: hightSome && item.different}">
              <div class="box-title">{{item.strBaseName}}</div>
              <div class="box-body"
                   v-for="(v,i) in item.comBaseInfoArr"
                   :key="i">
                <span v-if="v.baseInfoValue!='undefined'">{{v.baseInfoValue}}</span>
                <span v-else></span>
              </div>
            </div>
          </div>
        </div>
        <div class="compare-item"
             v-show="compareList.length>0"
             ref="qualify">
          <div class="compare-item-title"
               :style="{width:compareItemTItleWidth+'px'}">资质信息</div>
          <div class="compare-item-content">
            <div class="box-content"
                 v-for="(item,index) in compareList"
                 :key="index"
                 v-show="hideSome ? (hideSome ? item.different : true) : (showSome ? item.isSame : true)"
                 :class="{high: hightSome && item.different}">
              <div class="box-title">{{item.strQualifyName}}</div>
              <div class="box-body"
                   v-for="(v,i) in item.comQualifyInfoArr"
                   :key="i">
                <span v-if="v.isHave && v.levelNmae!='undefined'">{{v.levelName}}</span>
                <span v-else-if="v.isHave">不分等级</span>
                <span v-else></span>
              </div>
              <div class="placeholder"></div>
            </div>
          </div>
        </div>
        <div class="compare-item"
             ref="register"
             v-show="compareList4Register.length>0">
          <div class="compare-item-title"
               :style="{width:compareItemTItleWidth+'px'}">人员信息</div>
          <div class="compare-item-content">
            <div class="box-content"
                 v-for="(item,index) in compareList4Register"
                 :key="index"
                 v-show="hideSome ? (hideSome ? item.different : true) : (showSome ? item.isSame : true)"
                 :class="{high: hightSome && item.different}">
              <div class="box-title">{{item.registerTypeName}}</div>
              <div class="box-body"
                   v-for="(v,i) in item.comRegisterLst"
                   :key="i">
                <span v-if="v.registerNum">{{v.registerNum}}个</span>
                <span v-else></span>
              </div>
              <div class="placeholder"></div>
            </div>
          </div>
        </div>
        <div class="compare-item"
             ref="achievement"
             v-if="compareList4achievement.length>0">
          <div class="compare-item-title"
               :style="{width:compareItemTItleWidth+'px'}">业绩信息</div>
          <div class="compare-item-content">
            <div class="box-content"
                 v-for="(item,index) in compareList4achievement"
                 :key="index"
                 v-show="hideSome ? (hideSome ? item.different : true) : (showSome ? item.isSame : true)"
                 :class="{high: hightSome && item.different}">
              <div class="box-title">{{item.achievementTypeName}}</div>
              <div class="box-body"
                   v-for="(v,i) in item.comAchievementLst"
                   :key="i">
                <div v-if="v.nums">
                  <div>{{v.nums}}个</div>
                  <div>{{v.amount}}万人民币</div>
                </div>
                <div v-else></div>
              </div>
              <div class="placeholder"></div>
            </div>
          </div>
        </div>
        <div class="compare-item"
             ref="awards"
             v-if="compareList4Awards.length>0">
          <div class="compare-item-title"
               :style="{width:compareItemTItleWidth+'px'}">奖项信息</div>
          <div class="compare-item-content">
            <div class="box-content"
                 v-for="(item,index) in compareList4Awards"
                 :key="index"
                 v-show="hideSome ? (hideSome ? item.different : true) : (showSome ? item.isSame : true)"
                 :class="{high: hightSome && item.different}">
              <div class="box-title">{{item.awardsName}}</div>
              <div class="box-body"
                   v-for="(v,i) in item.comAwardsLsst"
                   :key="i">
                <span v-if="v.nums">{{v.nums}}个</span>
                <span v-else></span>
              </div>
              <div class="placeholder"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="side-anchor-menu"
         v-show="false">
      <div class="side-anchor-menu-box">
        <div class="side-anchor-menu-box-inner side-anchor-menu-scroll">
          <ul>
            <li v-for="(item,index) in anchorList"
                :key="index"
                :class="{ active: activeStep === index }"
                v-show="item.isShow">
              <span><a @click="scrollToView(item.element,index)">{{item.title}}</a></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <dialogviporlogin ref="dialogviporlogin"></dialogviporlogin>
    <el-dialog title="选择机构"
               v-dialogDrag
               :visible.sync="dialogCompanyVisible"
               :close-on-click-modal="false"
               width="900px">
      <el-form ref="form"
               :model="companyFormSearch"
               @submit.native.prevent
               label-width="80px">
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="机构名称">
              <el-input v-model="companyFormSearch.companyName"
                        placeholder="请输入机构名称"
                        clearable
                        @keyup.enter.native="handleEnterKey"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="机构属地">
              <el-input v-model="companyFormSearch.registerAddr"
                        placeholder="请输入机构属地"
                        clearable
                        @keyup.enter.native="handleEnterKey"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-button type="primary"
                       @click="handleEnterKey">搜索</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-table :data="companyTableData"
                stripe
                @row-click="companyRowClick"
                style="width: 100%">
        <template slot="empty">
          <el-empty description="哎呀，未查询到相关机构信息"></el-empty>
        </template>
        <el-table-column label="机构名称"
                         header-align="center"
                         align="left">
          <template slot-scope="scope">
            <el-radio v-model="companyChecked"
                      :label="scope.row.id">{{scope.row.mingCh}}</el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="leiX"
                         label="机构类型"
                         align="center">
        </el-table-column>
        <el-table-column prop="diZh"
                         align="center"
                         label="注册属地">
        </el-table-column>
      </el-table>
      <div class="company-page">
        <el-pagination background
                       layout="prev, slot, next"
                       @current-change="handleCurrentChange"
                       :total="companyCnt"
                       :current-page.sync="companyCurr"
                       :hide-on-single-page="true">
          <span class="current-page">{{companyCurr}}</span>
        </el-pagination>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="danger"
                   @click="dialogCompanyVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="handleClickDetermine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import dialogviporlogin from '@/components/dialogviporlogin'
import { mapGetters } from 'vuex'
import { getCompanyList4delivery, compareQualify4Company } from '@/api/organization'
const emptyObj = {
  mingCh: '机构名称'
}
const COMPANYLIST = [
  {
    mingCh: '机构名称'
  },
  {
    mingCh: '机构名称'
  },
  {
    mingCh: '机构名称'
  },
  {
    mingCh: '机构名称'
  }
]
export default {
  name: 'compareOrg',
  data() {
    return {
      // 资质对比列表
      compareList: [],
      // 基本信息对比列表
      compareList4BaseInfo: [],
      // 人员信息对比列表
      compareList4Register: [],
      // 业绩信息对比列表
      compareList4achievement: [],
      // 奖项信息对比列表
      compareList4Awards: [],
      companyList: [],
      hideSome: false,
      hightSome: true,
      showSome: false,
      onfixed: 0,
      fixedleft: 0,
      dialogCompanyVisible: false,
      companyChecked: -1,
      companyFormSearch: {
        companyName: '',
        registerAddr: '',
        start: 0,
        limit: 10
      },
      companyTableData: [],
      companyCnt: 0,
      // 点击的第几个换机构
      companyIdx: 0,
      companyCurr: 1,
      anchorList: [
        {
          element: 'baseInfo',
          title: '基本信息',
          isShow: false
        }, {
          element: 'qualify',
          title: '资质信息',
          isShow: false
        }, {
          element: 'register',
          title: '人员信息',
          isShow: false
        }, {
          element: 'achievement',
          title: '业绩信息',
          isShow: false
        }, {
          element: 'awards',
          title: '奖项信息',
          isShow: false
        }
      ],
      activeStep: '',
      scrollTop: 0,
      oldScrollTop: 0,
      compareItemTItleWidth: 1210,
      canSee: true,
      viporlogin: 'login'
    }
  },
  components: {
    dialogviporlogin
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  },
  methods: {
    // 页面滚动 -处理头部固定和锚点定位
    handleScroll() {
      // 处理头部固定
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      const clientWidth = document.body.clientWidth
      const scrollLeft = window.pageXOffset
      let fixedleft = 0
      if (clientWidth > 968) {
        fixedleft = (clientWidth - 968) / 2 - scrollLeft
      } else {
        fixedleft = ~scrollLeft
      }
      this.fixedleft = `${fixedleft}px`
      this.onfixed = scrollTop >= 200
      // 处理锚点滚动选项
      this.scrollTop = scrollTop
    },
    // 删除对比项
    delCompareItem(index) {
      // this.companyList[index] = emptyObj
      // this.initCompare()
      const List = sessionStorage.getItem('compnayCompareList') ? JSON.parse(sessionStorage.getItem('compnayCompareList')) : []
      const idx = List.findIndex(value => value.id === this.companyList[index].id)
      if (idx > -1) {
        List.splice(idx, 1)
        sessionStorage.setItem('compnayCompareList', JSON.stringify(List))
      }
      this.companyList.splice(index, 1)
      // 如果小于四个补充
      if (this.companyList.length < 4) {
        this.compareItemTItleWidth = 1210
        this.companyList.push(...Array.from({ length: 4 - this.companyList.length }, () => JSON.parse(JSON.stringify(emptyObj))))
      } else {
        this.compareItemTItleWidth = this.compareItemTItleWidth - 240
      }
      const newArr = this.companyList.filter(item => item.id)
      if (newArr.length === 0) {
        // 资质信息
        this.compareList = []
        // 基本信息对比列表
        this.compareList4BaseInfo = []
        // 人员信息对比列表
        this.compareList4Register = []
        // 业绩信息对比列表
        this.compareList4achievement = []
        // 奖项信息对比列表
        this.compareList4Awards = []
        this.anchorList = [
          {
            element: 'baseInfo',
            title: '基本信息',
            isShow: false
          }, {
            element: 'qualify',
            title: '资质信息',
            isShow: false
          }, {
            element: 'register',
            title: '人员信息',
            isShow: false
          }, {
            element: 'achievement',
            title: '业绩信息',
            isShow: false
          }, {
            element: 'awards',
            title: '奖项信息',
            isShow: false
          }
        ]
        sessionStorage.removeItem('compnayCompareList')
        this.$forceUpdate()
      } else {
        this.initCompare()
      }
    },
    // 更换位置
    changePosition(index) {
      const idx1 = index
      const idx2 = index + 1
      this.companyList.splice(idx2, 1, ...this.companyList.splice(idx1, 1, this.companyList[idx2]))
      // 资质信息
      for (const item of this.compareList) {
        item.comQualifyInfoArr.splice(idx2, 1, ...item.comQualifyInfoArr.splice(idx1, 1, item.comQualifyInfoArr[idx2]))
      }
      // 基本信息
      for (const item of this.compareList4BaseInfo) {
        item.comBaseInfoArr.splice(idx2, 1, ...item.comBaseInfoArr.splice(idx1, 1, item.comBaseInfoArr[idx2]))
      }
      // 注册人员信息
      for (const item of this.compareList4Register) {
        item.comRegisterLst.splice(idx2, 1, ...item.comRegisterLst.splice(idx1, 1, item.comRegisterLst[idx2]))
      }
      // 业绩信息
      for (const item of this.compareList4achievement) {
        item.comAchievementLst.splice(idx2, 1, ...item.comAchievementLst.splice(idx1, 1, item.comAchievementLst[idx2]))
      }
      // 奖项信息
      for (const item of this.compareList4Awards) {
        item.comAwardsLsst.splice(idx2, 1, ...item.comAwardsLsst.splice(idx1, 1, item.comAwardsLsst[idx2]))
      }
      this.$forceUpdate()
    },
    // 换机构按钮
    changeCompany(index) {
      if (!this.canSee) {
        this.$refs.dialogviporlogin.handleShow(this.viporlogin)
        return
      }
      this.companyIdx = index
      this.dialogCompanyVisible = true
      this.companyChecked = -1
      this.companyCurr = 1
      this.companyFormSearch = {
        companyName: '',
        registerAddr: '',
        start: 0,
        limit: 10
      }
      this.getCompanyList()
    },
    // 机构弹框搜索按钮和输入框回车事件
    handleEnterKey() {
      this.companyFormSearch.start = 0
      this.getCompanyList()
    },
    // 机构列表分页事件
    handleCurrentChange(val) {
      this.companyFormSearch.start = (val - 1) * this.companyFormSearch.limit
      this.getCompanyList()
    },
    // 获取机构列表
    getCompanyList() {
      getCompanyList4delivery(this.companyFormSearch).then(data => {
        if (data.ok) {
          this.companyTableData = data.lst
          if (data.cnt > 100) {
            this.companyCnt = 100
          } else {
            this.companyCnt = data.cnt
          }
        } else {
          this.$notify.error({
            title: '错误',
            message: data.msg
          })
        }
      }).catch(error => {
        this.$notify.error({
          title: '错误',
          message: error
        })
      })
    },
    // 选择机构确定按钮
    handleClickDetermine() {
      if (this.companyChecked === -1) {
        this.$message({
          showClose: true,
          message: '请选择机构',
          type: 'warning'
        })
        return
      }
      const index = this.companyList.findIndex((value, index, arr) => value.id === this.companyChecked)
      if (index !== -1) {
        this.$message({
          showClose: true,
          message: '该机构已经添加到对比中，无需再次添加',
          type: 'warning'
        })
        return
      }
      const obj = {}
      for (const item of this.companyTableData) {
        if (item.id === this.companyChecked) {
          obj.mingCh = item.mingCh
          obj.id = item.id
          break
        }
      }
      this.companyList[this.companyIdx] = obj
      this.dialogCompanyVisible = false
      this.initCompare()
    },
    // 初始化对比信息
    initCompare() {
      const companyArr = []
      const compnayCompareList = []
      for (const item of this.companyList) {
        if (item.id !== undefined) {
          companyArr.push(item.id)
          compnayCompareList.push({
            id: item.id,
            mingCh: item.mingCh
          })
        }
      }
      sessionStorage.setItem('compnayCompareList', JSON.stringify(compnayCompareList))
      if (companyArr.length === 0) {
        return
      }
      const params = {
        operateID: 1,
        qiYIDlist: companyArr
      }
      compareQualify4Company(params).then(data => {
        if (data.ok) {
          const COMPARELIST = data.lst || []
          for (const item of COMPARELIST) {
            const comQualifyInfoArr = item.comQualifyInfoArr
            const diffSet = new Set()
            for (const v of comQualifyInfoArr) {
              if (Object.keys(v).length !== 0) {
                diffSet.add(`${v.isHave}@${v.levelName}`)
              }
            }

            // 根据选择的机构进行排序
            let newComQualifyInfoArr = []
            for (const value of item.comQualifyInfoArr) {
              const companyID = value.companyID
              const index = this.companyList.findIndex((cv, index, arr) => cv.id === companyID)
              newComQualifyInfoArr[index] = value
            }

            if (newComQualifyInfoArr.length < this.companyList.length) {
              const arrLength = this.companyList.length - newComQualifyInfoArr.length
              const arr = Array(arrLength).fill({}, 0, arrLength)
              newComQualifyInfoArr = [...newComQualifyInfoArr, ...arr]
            }
            for (let i = 0; i < newComQualifyInfoArr.length; i++) {
              if (!newComQualifyInfoArr[i]) {
                newComQualifyInfoArr[i] = {}
              }
            }
            item.comQualifyInfoArr = newComQualifyInfoArr
            // 是否位不同项，false为相同同项，true为不相同项
            item.different = !(diffSet.size === 1)
            item.isSame = diffSet.size === 1
          }
          this.compareList = COMPARELIST
          if (this.compareList.length === 0) {
            this.anchorList[1].isShow = false
          } else {
            this.anchorList[1].isShow = true
          }
          this.initBaseInfoCompare(data.baseinfoLst || [])
          this.initRegisterCompare(data.registerLst || [])
          this.initAchievementCompare(data.achievementLst || [])
          this.initAwardsCompare(data.awardsLst || [])
        } else {
          this.$notify.error({
            title: '错误',
            message: data.msg
          })
        }
      }).catch(error => {
        this.$notify.error({
          title: '错误',
          message: error
        })
      })
    },
    // 结构化奖项对比信息
    initAwardsCompare(awardsLst) {
      const COMPARELIST = awardsLst
      for (const item of COMPARELIST) {
        const comAwardsLsst = item.comAwardsLsst
        const diffSet = new Set()
        for (const v of comAwardsLsst) {
          if (Object.keys(v).length !== 0) {
            diffSet.add(`${v.nums}`)
          }
        }

        // 根据选择的机构进行排序
        let newComAwardsLsst = []
        for (const value of item.comAwardsLsst) {
          const companyID = value.companyID
          const index = this.companyList.findIndex((cv, index, arr) => cv.id === companyID)
          newComAwardsLsst[index] = value
        }

        if (newComAwardsLsst.length < this.companyList.length) {
          const arrLength = this.companyList.length - newComAwardsLsst.length
          const arr = Array(arrLength).fill({}, 0, arrLength)
          newComAwardsLsst = [...newComAwardsLsst, ...arr]
        }
        for (let i = 0; i < newComAwardsLsst.length; i++) {
          if (!newComAwardsLsst[i]) {
            newComAwardsLsst[i] = {}
          }
        }
        item.comAwardsLsst = newComAwardsLsst
        // 是否位不同项，false为相同同项，true为不相同项
        item.different = !(diffSet.size === 1)
        item.isSame = diffSet.size === 1
      }
      this.compareList4Awards = COMPARELIST
      if (this.compareList4Awards.length === 0) {
        this.anchorList[4].isShow = false
      } else {
        this.anchorList[4].isShow = true
      }
    },
    // 结构化业绩对比信息
    initAchievementCompare(achievementLst) {
      const COMPARELIST = achievementLst
      for (const item of COMPARELIST) {
        const comAchievementLst = item.comAchievementLst
        const diffSet = new Set()
        for (const v of comAchievementLst) {
          if (Object.keys(v).length !== 0) {
            diffSet.add(`${v.nums}@${v.amount}`)
          }
        }

        // 根据选择的机构进行排序
        let newComAchievementLst = []
        for (const value of item.comAchievementLst) {
          const companyID = value.companyID
          const index = this.companyList.findIndex((cv, index, arr) => cv.id === companyID)
          newComAchievementLst[index] = value
        }

        if (newComAchievementLst.length < this.companyList.length) {
          const arrLength = this.companyList.length - newComAchievementLst.length
          const arr = Array(arrLength).fill({}, 0, arrLength)
          newComAchievementLst = [...newComAchievementLst, ...arr]
        }
        for (let i = 0; i < newComAchievementLst.length; i++) {
          if (!newComAchievementLst[i]) {
            newComAchievementLst[i] = {}
          }
        }
        item.comAchievementLst = newComAchievementLst
        // 是否位不同项，false为相同同项，true为不相同项
        item.different = !(diffSet.size === 1)
        item.isSame = diffSet.size === 1
      }
      this.compareList4achievement = COMPARELIST
      if (this.compareList4achievement.length === 0) {
        this.anchorList[3].isShow = false
      } else {
        this.anchorList[3].isShow = true
      }
    },
    // 结构化人员对比信息
    initRegisterCompare(registerLst) {
      const COMPARELIST = registerLst
      for (const item of COMPARELIST) {
        const comRegisterLst = item.comRegisterLst
        const diffSet = new Set()
        for (const v of comRegisterLst) {
          if (Object.keys(v).length !== 0) {
            diffSet.add(`${v.registerNum}`)
          }
        }

        // 根据选择的机构进行排序
        let newComRegisterLst = []
        for (const value of item.comRegisterLst) {
          const companyID = value.companyID
          const index = this.companyList.findIndex((cv, index, arr) => cv.id === companyID)
          newComRegisterLst[index] = value
        }

        if (newComRegisterLst.length < this.companyList.length) {
          const arrLength = this.companyList.length - newComRegisterLst.length
          const arr = Array(arrLength).fill({}, 0, arrLength)
          newComRegisterLst = [...newComRegisterLst, ...arr]
        }
        for (let i = 0; i < newComRegisterLst.length; i++) {
          if (!newComRegisterLst[i]) {
            newComRegisterLst[i] = {}
          }
        }
        item.comRegisterLst = newComRegisterLst
        // 是否位不同项，false为相同同项，true为不相同项
        item.different = !(diffSet.size === 1)
        item.isSame = diffSet.size === 1
      }
      this.compareList4Register = COMPARELIST
      if (this.compareList4Register.length === 0) {
        this.anchorList[2].isShow = false
      } else {
        this.anchorList[2].isShow = true
      }
    },
    // 结构化基本信息对比项
    initBaseInfoCompare(baseinfoLst) {
      const COMPARELIST = baseinfoLst
      for (const item of COMPARELIST) {
        const comBaseInfoArr = item.comBaseInfoArr
        const diffSet = new Set()
        for (const v of comBaseInfoArr) {
          if (Object.keys(v).length !== 0) {
            diffSet.add(`${v.baseInfoValue}`)
          }
        }

        // 根据选择的机构进行排序
        let newComBaseInfoArr = []
        for (const value of item.comBaseInfoArr) {
          const companyID = value.companyID
          const index = this.companyList.findIndex((cv, index, arr) => cv.id === companyID)
          newComBaseInfoArr[index] = value
        }

        if (newComBaseInfoArr.length < this.companyList.length) {
          const arrLength = this.companyList.length - newComBaseInfoArr.length
          const arr = Array(arrLength).fill({}, 0, arrLength)
          newComBaseInfoArr = [...newComBaseInfoArr, ...arr]
        }
        for (let i = 0; i < newComBaseInfoArr.length; i++) {
          if (!newComBaseInfoArr[i]) {
            newComBaseInfoArr[i] = {}
          }
        }
        item.comBaseInfoArr = newComBaseInfoArr
        // 是否位不同项，false为相同同项，true为不相同项
        item.different = !(diffSet.size === 1)
        item.isSame = diffSet.size === 1
      }
      this.compareList4BaseInfo = COMPARELIST
      if (this.compareList4BaseInfo.length === 0) {
        this.anchorList[0].isShow = false
      } else {
        this.anchorList[0].isShow = true
      }
    },
    // 机构列表点击选中事件
    companyRowClick(row) {
      this.companyChecked = row.id
    },
    // 添加机构
    addCompareItem() {
      this.companyList.push(JSON.parse(JSON.stringify(emptyObj)))
      for (const item of this.compareList) {
        item.comQualifyInfoArr.push({})
      }
      // 基本信息
      for (const item of this.compareList4BaseInfo) {
        item.comBaseInfoArr.push({})
      }
      // 注册人员信息
      for (const item of this.compareList4Register) {
        item.comRegisterLst.push({})
      }
      // 业绩信息
      for (const item of this.compareList4achievement) {
        item.comAchievementLst.push({})
      }
      // 奖项信息
      for (const item of this.compareList4Awards) {
        item.comAwardsLsst.push({})
      }
      this.compareItemTItleWidth = this.compareItemTItleWidth + 240
    },
    // 跳转到机构详情页面
    handleLinkToDetail(index) {
      const id = this.companyList[index].id
      if (id) {
        const routeData = this.$router.resolve({
          name: 'orgDetail',
          params: { id }
        })
        window.open(routeData.href, '_blank')
      }
    },
    // 滚动到对应的锚点
    scrollToView(name, index) {
      // this.activeStep = index
      this.$nextTick(() => {
        this.$refs[name].scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      })
    },
    authCheck() {
      const authObj = authenticate('compareOrg', this.memberType)
      if (authObj.flag) {
        this.canSee = true
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }

      // 初始化页面
      this.initPage()
    },
    initPage() {
      let List = null
      if (this.canSee) {
        List = sessionStorage.getItem('compnayCompareList') ? JSON.parse(sessionStorage.getItem('compnayCompareList')) : []
      }

      if (List) {
        let compareConpanyList = []
        for (const iterator of List) {
          const obj = {
            id: iterator.id,
            mingCh: iterator.mingCh
          }
          compareConpanyList.push(obj)
        }
        if (compareConpanyList.length < 4) {
          const arrLength = 4 - compareConpanyList.length
          const arr = Array(arrLength).fill(JSON.parse(JSON.stringify(emptyObj)), 0, arrLength)
          compareConpanyList = [...compareConpanyList, ...arr]
        }
        this.companyList = JSON.parse(JSON.stringify(compareConpanyList))
      } else {
        this.companyList = JSON.parse(JSON.stringify(COMPANYLIST))
      }
      this.initCompare()
    }
  },
  watch: {
    // 显示不同项
    hideSome(val) {
      if (val) {
        this.showSome = false
      }
    },
    // 显示相同项
    showSome(val) {
      if (val) {
        this.hideSome = false
        this.hightSome = false
      }
    },
    // 高亮显示不同项
    hightSome(val) {
      if (val) {
        this.showSome = false
      }
    },
    // 监听滚动结束 锚点定位
    scrollTop(newValue, oldValue) {
      setTimeout(() => {
        if (newValue === window.scrollY) { // 延时执行后当newValue等于window.scrollY，代表滚动结束
          // console.log('滚动结束')
          this.oldScrollTop = newValue // 每次滚动结束后都要给oldScrollTop赋值
          const scrollItems = document.querySelectorAll('.compare-item')
          for (let i = scrollItems.length - 1; i >= 0; i--) {
            // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
            const judge =
              newValue >=
              scrollItems[i].offsetTop - scrollItems[0].offsetTop
            if (judge) {
              this.activeStep = i
              break
            }
          }
        };
      }, 20) // 必须使用延时器，否则每次newValue和window.scrollY都相等，无法判断，20ms刚好大于watch的侦听周期，故延时20ms
      if (this.oldScrollTop === oldValue) { // 每次滚动开始时oldScrollTop与oldValue相等
        // console.info('滚动开始')
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    window.onresize = () => {
      this.handleScroll()
    }
    if (getToken()) {
      if (this.memberType === 'tourist') {
        this.$store.dispatch('getMemberInfo').then(res => {
          this.authCheck()
        })
      } else {
        this.authCheck()
      }
    } else {
      this.authCheck()
    }
  }
}
</script>

<style lang="scss" scoped>
$contentWidth: 1200px;
$widthContainer: 1200px;
.company-container {
  width: 1200px;
  margin: 0 auto;
}

// 比对内容
.compare {
  &-container {
    width: $widthContainer;
    margin: 0 auto;
    font-size: 14px;
    padding-bottom: 20px;
    min-height: 450px;
  }

  &-header-fixed {
    position: fixed;
    top: 0;
    z-index: 9;
    .compare-header-tools {
      border-bottom: 1px solid #ddd;
    }
    .compare-header-item {
      border-bottom: 1px solid #ddd;
    }
  }

  &-header {
    display: flex;
    height: 150px;
    &-tools {
      width: 250px;
      align-items: center;
      background: #f2f6fc;
      border-top: 1px solid #dddddd;
      border-left: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
      border-bottom: 1px solid #ddd;
      text-align: center;
      padding-top: 30px;
      flex-shrink: 0;

      .el-checkbox {
        display: block;
      }

      .screen-info {
        display: inline-block;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        background: #fff;
        border: 1px solid #dcdfe6;
        color: #606266;
        -webkit-appearance: none;
        text-align: center;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        outline: 0;
        margin: 0;
        -webkit-transition: 0.1s;
        transition: 0.1s;
        font-weight: 500;
        padding: 5px 20px;
        font-size: 14px;
        border-radius: 4px;
        margin-top: 6px;
        background: #398ce2;
        border: 1px solid #398ce2;
        color: #fff;
      }
    }

    &-item {
      width: 240px;
      position: relative;
      padding: 5px 15px;
      border-right: 1px solid #ddd;
      border-top: 1px solid #ddd;
      flex-shrink: 0;
      background: #fff;
      border-bottom: 1px solid #ddd;
      .delete-item {
        position: absolute;
        top: 0px;
        right: 0px;
        font-size: 20px;
        color: #bfbfbf;
        cursor: pointer;
        &:hover {
          color: #fe6811;
        }
      }

      &:nth-last-child(2) {
        .change-item {
          display: none;
        }
      }

      .change-item {
        width: 36px;
        height: 36px;
        position: absolute;
        top: 65px;
        right: -18px;
        text-align: center;
        z-index: 6;
        background: #dcdfe6;
        line-height: 36px;
        border-radius: 50%;
        cursor: pointer;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        font-size: 16px;
        &:hover {
          background: #398ce2;
          color: #fff;
        }
      }

      .product {
        &-title {
          margin-top: 40px;
          color: #333;
          line-height: 20px;
          height: 42px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-align: center;
          cursor: pointer;
        }

        &-change {
          padding-top: 10px;
        }

        &-tools {
          margin-top: 10px;
          text-align: center;
          button {
            display: inline-block;
            line-height: 1;
            white-space: nowrap;
            cursor: pointer;
            background: #fff;
            border: 1px solid #dcdfe6;
            color: #606266;
            -webkit-appearance: none;
            text-align: center;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            outline: 0;
            margin: 0;
            -webkit-transition: 0.1s;
            transition: 0.1s;
            font-weight: 500;
            padding: 10px 30px;
            font-size: 14px;
            border-radius: 4px;
            margin-left: 10px;
          }
          .collection {
            background: #fe6811;
            border: 1px solid #fe6811;
            color: #fff;
          }
        }
      }
    }

    &-add {
      width: 40px;
      flex-shrink: 0;
      position: relative;
      button {
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 40px;
        font-size: 14px;
        height: 100px;
        color: #fff;
        border: 1px solid;
        background-color: #398ce2;
        border-color: #398ce2;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        cursor: pointer;
      }
    }
  }

  &-content {
    .box {
      &-content {
        display: flex;

        &:first-child {
          .box-title {
            border-top: 1px solid #dddddd;
          }

          .box-body {
            border-top: 1px solid #dddddd;
          }
        }
        &:hover {
          .box-title {
            border-bottom: 1px solid #f43636;
          }
          .box-body {
            border-bottom: 1px solid #f43636;
          }
        }

        &.high {
          .box-body {
            background: #ffffce;
          }
        }

        .placeholder {
          width: 200px;
          flex-shrink: 0;
        }
      }

      &-title {
        width: 250px;
        background: #f2f6fc;
        padding: 15px;
        text-align: right;
        flex-shrink: 0;
        border-bottom: 1px solid #dddddd;
        border-left: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
      }

      &-body {
        padding: 15px;
        width: 240px;
        flex-shrink: 0;
        border-bottom: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        vertical-align: middle;
        text-align: center;
        background: #fff;
      }
    }
  }

  &-item {
    padding-top: 195px;
    margin-top: -195px;
    &-title {
      border: 1px solid #e6e6e6;
      padding: 15px;
      background: #fafafa;
      border-bottom: 0px;
      border-top: 0;
      min-width: 1210px;
    }
  }
}

.side-anchor {
  &-menu {
    position: fixed;
    left: 50%;
    top: 485px;
    margin-left: -600px;
    &-box {
      position: relative;
      z-index: 2;
      padding: 20px 0;
      margin-right: -4px;
      overflow: hidden;
      zoom: 1;
    }

    &-scroll {
      width: 135px;
      overflow: hidden;
      position: relative;
      zoom: 1;
      ul {
        zoom: 1;
        width: 135px;
        overflow: hidden;
      }

      li {
        position: relative;
        text-align: center;
        margin-top: 2px;
        height: 22px;
        span {
          padding: 0 16px 0 0;
          display: block;
          height: 22px;
        }

        a {
          color: #333;
          height: 20px;
          width: 96px;
          border: 1px solid #e6e6e6;
          background: #fafafa;
          display: block;
          cursor: pointer;
        }
        &.active {
          a {
            background-color: #038bf9;
            border-color: #038bf9;
            color: #fff;
          }
        }
      }
    }
  }
}

.company-page {
  text-align: right;
  margin-top: 10px;
}
</style>
